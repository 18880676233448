import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BModal } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import Vuelidate from 'vuelidate'
import Echo from 'laravel-echo'

// Providers
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import RepositoryProvider from '@/providers/RepositoryProvider'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// import Pusher from 'pusher-js'

dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

Object.defineProperties(Vue.prototype, {
	$date: {
		get() {
			return dayjs
		},
	},
})

// BSV Plugin Registration
Vue.use(Vuelidate)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin, {
	BModal: { noCloseOnBackdrop: true, noCloseOnEsc: true },
})

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.component('v-icon', Icon)
window.Pusher = require('pusher-js')

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: process.env.VUE_APP_KEY_REAL_TIME,
	// key: '02314fbb1c30ae5408aa',
	wsHost: 'ws-us2.pusher.com',
	cluster: 'us2',

	// namespace: 'App\Events',
	encrypted: true,
	forceTLS: false,
	/* wsPort: 6001,
  wssPort: 6001, */
	disableStats: true,
	enabledTransports: ['ws', 'wss'],
	/* auth: {
    headers: {
      authorization: 'Bearer ' + token,
    }
  } */
})
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
	provide: RepositoryProvider,
}).$mount('#app')
